import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { GRAY } from "../constants"

const StyledDiv = styled.div`
    background-color: ${GRAY};
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    bottom: 0;
    width: 100%;
`

const StyledParagraph = styled.p`
    color: #FFFFFF;
    opacity: .75;
`

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            gcp: file(relativePath: { eq: "gcp.png" }) {
                childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                }
            }
    }`)

    return (
        <StyledDiv>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Img fixed={data.gcp.childImageSharp.fixed} />
                <StyledParagraph>
                    © 2020 Raccoon Marketing Digital
                </StyledParagraph>
            </div>
        </StyledDiv>
    )
}

export default Footer