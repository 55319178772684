export const ORANGE = "#F9932C"

export const DARKORANGE = "#F98713"

export const GRAY = "#3B3C3D"

export const LIGHTGRAY = "#F5F5F5"

export const TAG_TRACKER_URL = "https://chrome.google.com/webstore/detail/tag-tracker/mgmfcddmmmohghdobbpoomekfhhndien/"

export const RACCOON_URL = "https://raccoon.ag/"

export const CLOUD_URL = "https://us-central1-raccoon-cluster.cloudfunctions.net/TagTrackerNotify"