import React from "react"
import { Reset } from "styled-reset"
import styled from "styled-components"

import Footer from "../components/Footer"

import { GRAY } from "../components/constants"

const StyledDiv = styled.div`
    font-family: 'Roboto';
    color: ${GRAY};
    position: relative;
`

const Layout = ({ children }) => {
    return (
        <StyledDiv>
            <Reset />
            {children}
            <Footer />
        </StyledDiv>
    )
}

export default Layout
